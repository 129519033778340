import React from "react";

import { format } from "@/utils/numbers";
import { trimEnd } from "@/utils/string";

export const SlowestTable = ({ data }) => {
  return (
    <table className="text-tight">
      <thead>
        <tr>
          <th>Host</th>
          <th></th>
          <th>Uri</th>
          <th>Time</th>
          <th>Bytes</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, idx) => {
          return (
            <tr key={row.host + row.uri + row.time + idx}>
              <td>{row.host}</td>
              <td>{row.status !== "200" ? row.status : ""}</td>
              <td>{trimEnd(row.uri, 60)}</td>
              <td>{row.reqTime}</td>
              <td>{row.bytes}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const ByHostTable = ({ data }) => {
  const hosts = Object.keys(data);
  return (
    <table className="text-tight">
      <thead>
        <tr>
          <th>Host</th>
          <th>Req Count</th>
          <th>Avg Time</th>
          <th>Sum Bytes</th>
        </tr>
      </thead>
      <tbody>
        {hosts.map((host) => {
          const row = data[host];
          return (
            <tr key={host}>
              <td>{host}</td>
              <td>{row.items.length}</td>
              <td>{row.avgReqTime}</td>
              <td>{format(row.sumBytes)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

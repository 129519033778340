import React from "react";
import format from "date-fns/format";
import hr from "date-fns/locale/hr";

import useRemoteApi from "@/services/useRemoteApi";
import CONFIG from "@/config.json";

import "./forecast.css";
import WeatherIcon from "./WeatherIcon";

const Forecast = () => {
  const { data } = useRemoteApi(
    `/weather/forecast?who=pccase&mock=${CONFIG.mockEverything}`,
    {
      refreshInterval: CONFIG.weather.forecastRefreshInterval,
    }
  );

  if (!data) {
    return <div>Loading...</div>;
  }

  // console.log("data", data.DailyForecasts[0]);

  return (
    <div className="mt-12">
      <div>{data.Headline.Text}</div>
      <div className="fc-container">
        {data.DailyForecasts.map((daily) => {
          return <DailyItem key={daily.Date} data={daily} />;
        })}
      </div>
    </div>
  );
};

export default Forecast;

const DailyItem = ({ data }) => {
  return (
    <div className="flex flex-col">
      <div className="text-center text-lg">
        {format(new Date(data.Date), "EEE", { locale: hr })}
      </div>
      <div className="flex flex-1 justify-center">
        <WeatherIcon code={data.Day.Icon} width={48} />
      </div>
      <div className="text-lg text-center">
        {data.Temperature.Maximum.Value}°
      </div>
      <div className="text-sm text-center">
        {data.Temperature.Minimum.Value}°
      </div>
    </div>
  );
};

import React from 'react';
import useErrorBoudary from 'use-error-boundary';

import useRemoteApi from '@/services/useRemoteApi';
import { format } from '@/utils/numbers';
import CONFIG from '@/config.json';

import { CurrencyChart, SingleLineChart } from './Chart';

const Finance = () => {
  const { ErrorBoundary, didCatch, error } = useErrorBoudary();

  if (didCatch) {
    return <div onClick={window.location.reload.bind(window.location)}>{error.message}</div>;
  }

  return (
    <ErrorBoundary>
      <div className="module text-tight">
        <UsdChart />
        <BtcChart />
      </div>
    </ErrorBoundary>
  );
};

export default Finance;

const UsdChart = () => {
  const { data: eurData } = useRemoteApi(`/wise/histogram?who=pccase&mock=${CONFIG.mockEverything}&target=EUR`, {
    refreshInterval: CONFIG.wise.refreshInterval,
  });

  if (!eurData) {
    return <div>Loading...</div>;
  }

  const chartData = eurData.map((x) => ({ time: x.time, eurRate: x.rate }));
  chartData.reverse();

  return (
    <>
      <div className="flex justify-between mt-8">
        <div className="font-bold">
          USD / <span className="text-blue-300">EUR</span>
        </div>
        <div className="text-2xl" style={{ marginBottom: '-12px' }}>
          <span className="text-blue-300">{eurData[0]?.rate}</span>
        </div>
      </div>
      <div className="text-xs">
        <CurrencyChart data={chartData} height={80} />
      </div>
    </>
  );
};

const BtcChart = () => {
  const { data } = useRemoteApi(`/crypto/klines?who=pccase&mock=${CONFIG.mockEverything}`, {
    refreshInterval: CONFIG.binance.refreshInterval,
  });

  const { data: ticker } = useRemoteApi(`/crypto/ticker?who=pccase&mock=${CONFIG.mockEverything}`, {
    refreshInterval: CONFIG.binance.refreshInterval,
  });

  if (!data) {
    return <div>Loading...</div>;
  }

  /**
   * [
  [
    1499040000000,      // Open time
    "0.01634790",       // Open
    "0.80000000",       // High
    "0.01575800",       // Low
    "0.01577100",       // Close
    "148976.11427815",  // Volume
    1499644799999,      // Close time
    "2434.19055334",    // Quote asset volume
    308,                // Number of trades
    "1756.87402397",    // Taker buy base asset volume
    "28.46694368",      // Taker buy quote asset volume
    "17928899.62484339" // Ignore.
  ]
]
   */

  const chartData = data.map((item) => ({
    time: item[6], // close time
    value: item[4], // close price
  }));

  // console.log("data", data);

  return (
    <>
      <div className="flex justify-between mt-8">
        <div className="font-bold">BTC/USDT</div>
        {ticker && (
          <div className="text-2xl text-yellow-300" style={{ marginBottom: '-12px' }}>
            {format(ticker.price, 0)}
          </div>
        )}
      </div>
      <SingleLineChart data={chartData} color="#FBBF24" height={80} />
    </>
  );
};

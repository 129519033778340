import _sortBy from "lodash/sortBy";
import _slice from "lodash/slice";

import { format } from "@/utils/numbers";

export const getSlowest = (data, count) => {
  const sorted = _sortBy(data, "reqTime");
  return _slice(sorted, sorted.length - count).reverse();
};

const _processTimeAndBytes = (data) => {
  const sums = data.reduce(
    (acc, cur) => {
      acc.sumReqTime = acc.sumReqTime + parseFloat(cur.reqTime);
      acc.sumBytes = acc.sumBytes + parseInt(cur.bytes, 10);
      return acc;
    },
    {
      sumReqTime: 0,
      sumBytes: 0,
    }
  );
  return {
    sumBytes: sums.sumBytes,
    avgReqTime:
      data.length > 0 ? format(sums.sumReqTime / data.length, 3) : "0.000",
  };
};

// expects data sorted by time ASC
const _createHistogram = (data) => {
  const interval = 5 * 60 * 1000; // 5 minutes
  const numberOfIntervals = (24 * 60 * 60 * 1000) / interval;
  const today = new Date();
  const todayUTCTs = Date.UTC(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0,
    0
  );

  const timeSeries = {};
  for (let i = 0; i < numberOfIntervals; i++) {
    const ts = todayUTCTs + i * interval;
    // const tsMax = ts + interval;
    timeSeries[ts] = [];
  }
  data.forEach((item) => {
    const ts = new Date(item.time).getTime();
    if (!isNaN(ts)) {
      const x = ts - (ts % interval);
      timeSeries[x].push(item);
    }
  });

  const tsKeys = Object.keys(timeSeries);
  const res = tsKeys.map((ts) => {
    const { sumBytes, avgReqTime } = _processTimeAndBytes(timeSeries[ts]);
    return {
      ts,
      count: timeSeries[ts].length,
      sumBytes,
      avgReqTime,
    };
  });

  return res;
};

export const metricsByHost = (data, whitelist) => {
  // console.time("metrics");
  // create initial state of whitelisted hosts as keys + others for spam requests
  const initialState = whitelist.reduce(
    (acc, cur) => {
      acc[cur] = {
        avgReqTime: 0,
        sumBytes: 0,
        items: [],
      };
      return acc;
    },
    {
      others: {
        avgReqTime: 0,
        sumBytes: 0,
        items: [],
      },
    }
  );
  // shove all requests into items by host
  const grouped = data.reduce((acc, cur) => {
    const key = whitelist.includes(cur.host) ? cur.host : "others";
    acc[key].items.push(cur);
    return acc;
  }, initialState);

  // calc stuff
  const hosts = Object.keys(grouped);
  const res = hosts.reduce((acc, host) => {
    const items = grouped[host].items;
    const { sumBytes, avgReqTime } = _processTimeAndBytes(items);
    acc[host] = {
      items,
      histogram: _createHistogram(items),
      avgReqTime,
      sumBytes,
    };
    return acc;
  }, {});
  // console.timeEnd("metrics");
  return res;
};

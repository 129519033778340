import React from "react";

import useRemoteApi from "@/services/useRemoteApi";

import * as utils from "./utils";
import { SlowestTable, ByHostTable } from "./components";
import "./monitor.css";

const HOST_WHITELIST = [
  "remote.hr",
  "baza.remote.hr",
  "dtd.remote.hr",
  "mg.remote.hr",
  "sprdex.com",
  "stonehouse-popozo.com",
  "take.hr",
  "dide-stipe.com",
  "dalmatia-excursions.com",
  "proizvodnja.dtd.hr",
];

/**
 * Overview
 * Raw tail
 *
 */

const MOCK = false;
const ServerMonitor = () => {
  const { data } = useRemoteApi(`/monitor/access?who=pccase&mock=${MOCK}`);
  const stats = useRemoteApi(`/monitor/stats`);

  if (!data || !stats.data) {
    return <div>Loading...</div>;
  }

  const slowest = utils.getSlowest(data, 14);
  const byHost = utils.metricsByHost(data, HOST_WHITELIST);

  // console.log("stats.data", stats.data);
  // console.log("byHost", byHost);

  return (
    <div className="overview-grid module">
      <SlowestTable data={slowest} />
      <ByHostTable data={byHost} />
    </div>
  );
};

export default ServerMonitor;

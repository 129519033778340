import useSwr from 'swr';

const baseUrl = 'https://remote.hr/api';
// const baseUrl = 'https://remote.hr/devapi';
// const baseUrl = 'http://localhost:5048/devapi';

const fetcher = async (url) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.');
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

const useRemoteApi = (path, options = null) => {
  if (!path) {
    throw new Error('Path is required');
  }

  const url = baseUrl + path;
  const { data, error } = useSwr(url, fetcher, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // Never retry on 404.
      if (error.status === 404) return;

      // Never retry for a specific key.
      if (key === '/api/user') return;

      // Only retry up to 30 minutes
      if (retryCount >= 30 * 2) return;

      // Retry after 30 seconds.
      setTimeout(() => revalidate({ retryCount }), 30000);
    },
    ...options,
  });

  return { data, error };
};

export default useRemoteApi;

import format from 'date-fns/format';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import addDays from 'date-fns/addDays';

export const combineCalendars = (data) => {
  return data.items.reduce((agg, cur) => {
    const markedItems = cur.items.map((x) => ({ ...x, calendar: cur.calendar }));
    return agg.concat(markedItems);
  }, []);
};

export const daysToShow = (start, numberOfDays) => {
  const end = addDays(start, numberOfDays);
  return eachDayOfInterval({ start, end }).map((x) => format(x, 'yyyy-MM-dd'));
};

import React from 'react';
import { useHistory } from 'react-router-dom';

import CONFIG from '@/config.json';
import useRemoteApi from '@/services/useRemoteApi';
import { format } from '@/utils/numbers';
import ProgressPie from '@/components/ProgressPie';

const ServerAtGlance = () => {
  const history = useHistory();
  const { data } = useRemoteApi(`/monitor/stats`, {
    refreshInterval: CONFIG.serverAtGlance.refreshInterval,
  });

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="text-tight cursor-pointer" onClick={() => history.push('/monitor')}>
      <div className="flex justify-between">
        <div className="font-bold">SERVER OVERVIEW</div>
        {data.platform.sshUsers > 0 && (
          <div className="text-danger text-sm">Open SSH connections: {data.platform.sshUsers}</div>
        )}
      </div>
      <div className="serverAtGlanceGrid">
        <div className="flex items-center">
          <table className="w-full text-sm">
            <thead>
              <tr>
                <th colSpan="2">CPU Load</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1m</td>
                <td>{format(data.cpu.loadAvg[0], 2)}</td>
              </tr>
              <tr>
                <td>5m</td>
                <td>{format(data.cpu.loadAvg[1], 2)}</td>
              </tr>
              <tr>
                <td>15m</td>
                <td>{format(data.cpu.loadAvg[2], 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <ProgressPie title="Mem" value={data.mem.usedMemPercentage} />
        </div>
        <div>
          <ProgressPie title="Drive" value={data.drive.usedPercentage} />
        </div>
      </div>
    </div>
  );
};

export default ServerAtGlance;

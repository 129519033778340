import React from 'react';

// import Calendar from '@/components/Calendar';
import Finance from '@/components/Finance';
import Investment from '@/components/Investment';
import Weather from '@/components/Weather';
import ServerAtGlance from '@/components/ServerAtGlance';

const Overview = () => {
  return (
    <div className="p-4 overview-layout">
      <Weather />
      {/* <Calendar /> */}
      <Investment />
      <div>
        <ServerAtGlance />
        <Finance />
      </div>
    </div>
  );
};

export default Overview;

import React from 'react';

import CONFIG from '@/config.json';
import useRemoteApi from '@/services/useRemoteApi';
import { format } from '@/utils/numbers';

const Investment = () => {
  const { data: cashData } = useRemoteApi('/invest/cash', {
    refreshInterval: CONFIG.trading212.refreshInterval,
  });
  const { data: portfolioData } = useRemoteApi('/invest/portfolio', {
    refreshInterval: CONFIG.trading212.refreshInterval,
  });

  return (
    <div className="pl-12">
      <div className="font-bold">T212</div>
      <div className="flex flex-1 w-full">
        <div className="pl-12">
          <table className="w-full text-sm">
            <thead>
              <tr>
                <th colSpan={2}>Overview</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>In</td>
                <td>{format(cashData?.invested, 2)}</td>
              </tr>
              <tr>
                <td>Free</td>
                <td>{format(cashData?.free, 2)}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{format(cashData?.total, 2)}</td>
              </tr>
              <tr>
                <td>Diff</td>
                <td>{format(cashData?.ppl, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="pl-12">
          <table className="w-full text-sm">
            <thead>
              <tr>
                <th colSpan={3}>Pie</th>
              </tr>
            </thead>
            <tbody>
              {portfolioData?.map((item) => (
                <tr key={item.ticker}>
                  <td>{item.ticker}</td>
                  <td>{format(item.currentPrice * item.quantity, 2)}</td>
                  <td>{item.ppl}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Investment;

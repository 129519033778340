import { useEffect, useState, useMemo, useRef } from "react";

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const useToggle = (initialState = false) => {
  const [isOn, _setIsOn] = useState(initialState);

  return useMemo(
    () => ({
      isOn,
      set: (s) => _setIsOn(s),
      toggle: () => _setIsOn((s) => !s),
      setOn: () => _setIsOn(true),
      setOff: () => _setIsOn(false),
    }),
    [isOn],
  );
};
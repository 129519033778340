const config = {
  mockEverything: false,
  calendar: {
    refreshInterval: 480000,
  },
  weather: {
    currentRefreshInterval: 2700000, // 45 minutes => 32 calls
    forecastRefreshInterval: 14400000, // 4 hours => 6 calls
  },
  serverAtGlance: {
    refreshInterval: 60000,
  },
  binance: {
    refreshInterval: 1800000, // 30 minutes
  },
  wise: {
    refreshInterval: 1800000,
  },
  trading212: {
    // 1 hour in ms
    refreshInterval: 3600000,
  },
};

export default config;

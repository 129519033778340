import useSwr from "swr";

const baseUrl = "https://remote.hr/api";

const fetcher = async (url) => {
  const res = await fetch(url);

  if (!res.ok) {
    const error = new Error("Ping failed.");
    error.status = res.status;
    throw error;
  }
  return;
}

const usePing = () => {
  const {data, error} = useSwr(baseUrl + "/ping", fetcher, {
    refreshInterval: 1000 * 60,
  });
  return {data, error}
}

export default usePing;
import React from 'react';
import clsx from 'clsx';
import useErrorBoudary from 'use-error-boundary';
import format from 'date-fns/format';
import hr from 'date-fns/locale/hr';
import { FiClipboard } from 'react-icons/fi';

import useRemoteApi from '@/services/useRemoteApi';
import CONFIG from '@/config.json';
import { combineCalendars, daysToShow } from '@/utils/calendar';

import { ItemFull } from '@/components/Calendar/components';

const Item = ({ data, index }) => {
  return (
    <div
      className={clsx(
        'flex flex-col ml-12 border-0',
        index !== 0 && 'border-1 border-solid border-gray-500 pl-12',
        'mb-4',
      )}
    >
      {data.map((item) => (
        <ItemFull key={item.start + item.creator} item={item} variant="compressed" />
      ))}
    </div>
  );
};

const ItemWrapperStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
};

const CalendarScene = () => {
  const { data } = useRemoteApi(`/g/calendar?who=pccase&mock=${CONFIG.mockEverything}`, {
    refreshInterval: CONFIG.calendar.refreshInterval,
  });

  if (!data) {
    return <div>Loading...</div>;
  }

  const combinedItems = combineCalendars(data).sort((a, b) => a.start.localeCompare(b.start));
  const days = daysToShow(new Date(), 3);

  return (
    <div style={ItemWrapperStyle}>
      {days.map((day, idx) => {
        const items = combinedItems.filter((item) => item.start.startsWith(day));
        return (
          <div key={day}>
            <div className="flex ml-12 mb-12 text-lg font-bold text-gray-400">
              <FiClipboard className="mr-8" /> {format(new Date(day), 'dd.MM.yyyy. EEEE', { locale: hr })}
            </div>
            <Item data={items} index={idx} />
          </div>
        );
      })}
    </div>
  );
};

const CalendarSceneWrapper = () => {
  const { ErrorBoundary, didCatch, error } = useErrorBoudary();

  if (didCatch) {
    return <div onClick={window.location.reload.bind(window.location)}>{error.message}</div>;
  }

  return (
    <ErrorBoundary>
      <CalendarScene />
    </ErrorBoundary>
  );
};

export default CalendarSceneWrapper;

import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const getColor = (value) => {
  let color = "#10b981";
  if (value > 40 && value <= 85) {
    color = "#F59E0B";
  }
  if (value > 85) {
    color = "#f87171";
  }
  return color;
};

const LabelStyle = {
  marginTop: "-65px",
};

const ProgressPie = ({ value, title }) => {
  const percentage = parseFloat(value);
  const data = [{ value: percentage }, { value: 100 - percentage }];
  const color = getColor(percentage);

  return (
    <>
      <PieChart height={100} width={100} margin={"-20px 0"}>
        <Pie
          startAngle={180}
          endAngle={540}
          innerRadius="65%"
          data={data}
          dataKey="value"
          labelLine={false}
          blendStroke
          isAnimationActive={true}
        >
          <Cell fill={color} />
          <Cell fill={"#374151"} />
        </Pie>
      </PieChart>
      <div className="flex flex-col items-center" style={LabelStyle}>
        <div>{title}</div>
        <div>{value}%</div>
      </div>
    </>
  );
};

export default ProgressPie;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import format from "date-fns/format";
import hr from "date-fns/locale/hr";
import { FiX } from "react-icons/fi";

import usePing from "@/services/usePing";
import { useInterval } from "@/utils/hooks";

const getPageName = (pathname, time) => {
  switch (pathname) {
    case "/":
      return format(time, "dd.MM.yyyy. eeee - HH:mm", { locale: hr });
    case "/monitor":
      return "> Server monitor";
    case "/calendar":
      return "> Calendar";
    default:
      return "> where are we?";
  }
};

const Header = () => {
  const history = useHistory();
  const [time, setTime] = useState(() => new Date());
  const { error } = usePing();

  useInterval(() => {
    setTime(new Date());
    // refresh every minute
  }, 1000 * 60 * 1);

  return (
    <div className="header text-tight flex justify-between">
      <div className="text-3xl cursor-pointer" onClick={window.location.reload.bind(window.location)}>
        {getPageName(history.location.pathname, time)}
        {error && (<span> - {error.message}</span>)}
      </div>
      {history.location.pathname !== "/" && (
        <button className="text-4xl" onClick={() => history.push("/")}>
          <FiX />
        </button>
      )}
    </div>
  );
};

export default Header;

import React, { useState } from 'react';
import clsx from 'clsx';
import format from 'date-fns/format';
import hr from 'date-fns/locale/hr';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { FiCoffee, FiClipboard, FiUser } from 'react-icons/fi';

import { useInterval } from '@/utils/hooks';

const NothingToDoStyle = {
  fontSize: '52px',
  border: '4px solid',
  borderRadius: '50%',
  height: '120px',
  width: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '12px',
};

const getColorClass = (calendarId) => {
  if (calendarId.includes('mixmode.ai')) {
    return 'text-yellow-500';
  }
  if (calendarId.includes('deel.com')) {
    return 'text-blue-400';
  }
  if (calendarId.includes('family')) {
    return 'text-pink-300';
  }
  return '';
};

export const Today = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="text-green-200 flex flex-col justify-center items-center">
        <div style={NothingToDoStyle}>
          <FiCoffee />
        </div>
        <div>Nothing to do today.</div>
        <div>Sehr gut!</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col ml-12 border-0 border-l border-solid border-gray-500 pl-12 mb-4 overflow-hidden">
      {data.map((item) => (
        <ItemFull key={item.start + item.creator} item={item} />
      ))}
    </div>
  );
};

export const Day = ({ day, data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="flex p-4 pt-12 text-lg font-bold text-green-200">
        <FiCoffee className="mr-8" /> {format(new Date(day), 'dd.MM.yyyy. EEEE', { locale: hr })}
      </div>
    );
  }
  return (
    <div>
      <div className="flex p-4 pt-12 text-lg font-bold text-gray-400">
        <FiClipboard className="mr-8" /> {format(new Date(day), 'dd.MM.yyyy. EEEE', { locale: hr })}
      </div>
      <div className="flex flex-col ml-12 border-0 border-l border-solid border-gray-500 pl-12 mb-4">
        {data.map((item) => (
          <Item key={item.start + item.creator} item={item} />
        ))}
      </div>
    </div>
  );
};

export const Item = ({ item }) => {
  return (
    <div className={clsx('flex', 'mb-4', getColorClass(item.calendar))}>
      <div className="mr-12">{format(new Date(item.start), 'HH:mm')}</div>
      <div>{item.summary}</div>
    </div>
  );
};

export const ItemFull = ({ item, variant }) => {
  const [now, setNow] = useState(() => new Date());
  const startDate = new Date(item.start);
  const endDate = new Date(item.end);
  const duration = formatDistanceStrict(startDate, endDate, {
    unit: 'minute',
    locale: hr,
  }).replace('minuta', 'min');

  useInterval(() => {
    setNow(new Date());
  }, 1000 * 60 * 5);

  let progressCls = '';
  if (now > startDate) {
    progressCls = 'text-accent';
    if (now > endDate) {
      progressCls = 'text-gray-500';
    }
  }

  const attendees = item.attendees ? item.attendees.length : '?';

  return (
    <div className={clsx('flex mb-12 text-lg', progressCls)}>
      <div className="mr-12 flex flex-col">
        <div className="text-2xl font-bold">{format(new Date(item.start), 'HH:mm')}</div>
        <div className="text-lg text-center">{duration}</div>
      </div>
      <div className="flex-1">
        <div className={clsx(variant === 'compressed' ? 'text-xl' : 'text-2xl', 'mb-4', getColorClass(item.calendar))}>
          {item.summary}
        </div>
        <div className={clsx('text-sm text-gray-400', progressCls)}>
          <div className="mb-2">{item.creator}</div>
          {variant !== 'compressed' && (
            <div className="flex items-center">
              <FiUser className="mr-4" /> {attendees} - {item.status}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

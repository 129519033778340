import React from "react";
import { WiStrongWind } from "react-icons/wi";

import useRemoteApi from "@/services/useRemoteApi";
import CONFIG from "@/config.json";

import WeatherIcon from "./WeatherIcon";
import Forecast from "./Forecast";

const Weather = () => {
  const { data, error } = useRemoteApi(
    `/weather/current?who=pccase&mock=${CONFIG.mockEverything}`,
    {
      refreshInterval: CONFIG.weather.currentRefreshInterval,
      errorRetryInterval: 60000,
    }
  );

  if (!data) {
    return <div className="module">Loading...</div>;
  }

  const curData = data[0];

  return (
    <div className="module" style={{ marginTop: "-12px" }}>
      {error && <div className="text-danger">API Error!</div>}
      <div className="flex text-tight">
        <WeatherIcon code={curData.WeatherIcon} />
        <div className="mt-32">
          <div className="flex">
            <div className="text-giga">{curData.Temperature.Metric.Value}°</div>
            <TempDeparture
              value={curData.Past24HourTemperatureDeparture.Metric.Value}
            />
          </div>
          <div className="tex-sm">
            Real feel: {curData.RealFeelTemperature.Metric.Value}°
          </div>
          <div className="flex items-center mt-8">
            <WiStrongWind style={{ fontSize: 22 }} />
            <span className="mr-4">
              {curData.Wind.Direction.Localized}
            </span>{" "}
            {curData.Wind.Speed.Metric.Value}
            {" > "}
            {curData.WindGust.Speed.Metric.Value}
            {curData.WindGust.Speed.Metric.Unit}
          </div>
        </div>
      </div>
      <div className="text-xl" style={{ marginTop: "-8px" }}>
        {curData.WeatherText}
      </div>
      <Forecast />
    </div>
  );
};

export default Weather;

const TempDeparture = ({ value }) => {
  if (Math.abs(value) < 0.5) {
    return null;
  }
  const imgSrc = value > 0 ? "thermometer-warmer" : "thermometer-colder";
  const valueStyle = {
    margin: "16px 0 0 -8px",
    fontSize: "18px",
  };

  return (
    <div className="flex">
      <img width={48} alt={imgSrc} src={`weatherIcons/${imgSrc}.svg`} />
      <span style={valueStyle}>{value}°</span>
    </div>
  );
};

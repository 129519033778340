import React from 'react';
import { ResponsiveContainer, YAxis, LineChart, Line, XAxis } from 'recharts';

export const CurrencyChart = ({ data, height }) => {
  return (
    <ResponsiveContainer width="99%" height={height}>
      <LineChart height={height} data={data}>
        <XAxis hide dataKey="time" />
        <YAxis hide yAxisId="eurRate" dataKey="eurRate" orientation="right" domain={['dataMin', 'dataMax']} />
        <Line type="monotone" dot={false} yAxisId="eurRate" dataKey="eurRate" stroke="#93c5fd" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export const SingleLineChart = ({ data, height, color }) => {
  return (
    <ResponsiveContainer width="99%" height={height}>
      <LineChart height={height} data={data}>
        <XAxis hide dataKey="time" />
        <YAxis hide dataKey="value" domain={['dataMin', 'dataMax']} />
        <Line type="monotone" dot={false} dataKey="value" stroke={color} strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

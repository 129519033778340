import React from "react";

/**
 * weather icons from
 * https://basmilius.github.io/weather-icons/index-fill.html
 * https://github.com/basmilius/weather-icons
 */

const iconCode = {
  1: "clear-day",
  2: "clear-day",
  3: "clear-day",
  4: "partly-cloudy-day",
  5: "haze-day",
  6: "cloudy",
  7: "cloudy",
  8: "overcast",
  11: "fog",
  12: "rain",
  13: "rain",
  14: "partly-cloudy-day-rain",
  15: "thunderstorms",
  16: "thunderstorms-day-rain",
  17: "thunderstorms-day",
  18: "rain",
  19: "cloudy",
  20: "overcast-day",
  21: "overcast-day",
  22: "snow",
  23: "partly-cloudy-day-snow",
  24: "sleet",
  25: "sleet",
  26: "sleet",
  29: "sleet",
  32: "wind",
  33: "clear-night",
  34: "partly-cloudy-night",
  35: "partly-cloudy-night",
  36: "partly-cloudy-night",
  37: "haze-night",
  38: "overcast-night",
  39: "partly-cloudy-night-rain",
  40: "partly-cloudy-night-rain",
  41: "thunderstorms-night",
  42: "thunderstorms-night-rain",
  43: "overcast-night",
  44: "overcast-night",
};

const WeatherIcon = ({ code, width }) => {
  const filename = iconCode[code] || "not-available";
  return (
    <div style={{ width: width || 160 }}>
      <img alt={filename} src={`weatherIcons/${filename}.svg`} />
    </div>
  );
};

export default WeatherIcon;

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useErrorBoundary } from 'use-error-boundary';

import CalendarScene from '@/scenes/CalendarScene';
import Overview from '@/scenes/Overview';
import ServerMonitor from '@/scenes/ServerMonitor';

import Header from '@/components/Header';

// 1280x400
function App() {
  const history = useHistory();
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();

  if (didCatch) {
    return (
      <div className="main" onClick={() => history.push('/')}>
        {error.message}
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <Router>
        <div className="main">
          <Switch>
            <Route path="/monitor/:view?">
              <Header />
              <ServerMonitor />
            </Route>
            <Route path="/calendar">
              <Header />
              <CalendarScene />
            </Route>
            <Route exact path="/">
              <Header />
              <Overview />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
